import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Authenticated from './containers/Authenticated/Authenticated'
import Unauthenticated from './containers/Unauthenticated/Unauthenticated';
import { MsalProvider } from '@microsoft/mgt-msal-provider';
import { Providers } from '@microsoft/mgt-element';

Providers.globalProvider = new MsalProvider({
    clientId: process.env.REACT_APP_CLIENTID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT}`,
    scopes: ['user.read'] // 'user.readbasic.all', 'group.read.all', 'user.readbasic.all', 'user.read'
});

function App() {

  return(
		<>
			<AuthenticatedTemplate>
				<Authenticated />
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<Unauthenticated />
			</UnauthenticatedTemplate>
		</>
  )
}

export default App;

